import http from './httpService'
const apiEndpoint = '/admin'

export const sendMasterList = async list => await http.put(apiEndpoint + "/masterWL", list).then(res => res);
export const sendUpdSplitList = async list => await http.put(apiEndpoint + "/checkUpdateSplits", list).then(res => res);
export const sendNewSponsor = async sponsor => await http.post(apiEndpoint + "/newSponsor", sponsor).then(res => res);
export const sendWeeklyInsights = async note => await http.post(apiEndpoint + "/sendWeeklyInsights", {note}).then(res => res);

// admin cleanup related stuff
export const userLoopStuff = async () => await http.get(apiEndpoint + "/userLoopStuff").then(res => res);
export const cleanWatchlists = async () => await http.get(apiEndpoint + "/cleanWatchlists").then(res => res);
export const cleanIndustries = async () => await http.get(apiEndpoint + "/cleanIndustries").then(res => res);

// Update database
export const updateAccumDistrADLine = async () => await http.get(apiEndpoint + "/updateAccumDistrADLine").then(res => res);
export const updateMktLeaders = async () => await http.get(apiEndpoint + "/updateMktLeaders").then(res => res);
export const sendUpdIPOList = async list => await http.put(apiEndpoint + "/checkUpdateIPOs", list).then(res => res);

export const updateEarningsCalendar = async () => await http.get(`${apiEndpoint}/updateEarningsCalendar`).then((res) => res);
export const updateDivHistory = async obj => await http.put(apiEndpoint + "/updateDivHistory", obj).then(res => res);
export const stWatchers = async () => await http.get(apiEndpoint + "/stWatchers").then(res => res);
export const updateUST = async () => await http.post(`${apiEndpoint}/updateUST`).then((res) => res);
export const populateDatabase = async () => await http.get(`${apiEndpoint}/listingUpdate`).then((res) => res);
export const updateTEIndex = async () => await http.get(`${apiEndpoint}/updateTEIndex`).then((res) => res);
export const updateNHNL = async () => await http.get(`${apiEndpoint}/updateNHNL`).then((res) => res);
export const updateGoldDeathCross = async () => await http.get(`${apiEndpoint}/updateGoldDeathCross`).then((res) => res);
export const updateAssets = async (type, letterStart = false) => await http.get(`${apiEndpoint}/updateAssets/${type}/${letterStart}`).then((res) => console.log(res));
export const updateVolPointUpDown = async () => await http.get(`${apiEndpoint}/updateVolPointUpDown`).then((res) => res);
export const updateFundamentals = async name => await http.post(`${apiEndpoint}/`, { data: name, dataType: "fundamentals" }).then((res) => res);
export const updateShareStatsHistorical = async () => await http.get(`${apiEndpoint}/updateShareStatsHistorical`).then((res) => res);
export const updateWeekInReview = async () => await http.get(`${apiEndpoint}/updateWeekInReview`).then((res) => res); 
export const populateCrypto = async () => await http.get(`${apiEndpoint}/populateCrypto`).then((res) => res);

export const updateSlopeShareStats = async () => await http.get(`${apiEndpoint}/updateSlopeShareStats`).then((res) => res);
export const updateStdDeviations = async () => await http.get(apiEndpoint + "/updateStdDeviations").then(res => res);

export const updateNHNLGDTableData = async () => await http.get(apiEndpoint + "/updateNHNLGDTableData").then(res => res);

export const getMAUs = async () => await http.get(apiEndpoint + "/maus").then(res => res);
export const getFeatureUsage = async () => await http.get(apiEndpoint + "/featureUsage").then(res => res);

export const postFatalError = async error => await http.post(apiEndpoint + "/fatalError", error).then(res => res);

// validation stuff
export const checkTechFundUpToDate = async days => await http.get(`${apiEndpoint}/checkTechFundUpToDate/${days}`).then(res => res);

// Screen of the week
export const sendScreenOfTheWeek = async (screenName, summary, settings, tickers, link) => await http.post(`${apiEndpoint}/sendScreenOfTheWeek`, { screenName, summary, settings, tickers, link }).then(res => res);